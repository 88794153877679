/**
 * Higher Order component for private route
 * Note: This is well integrated with auth security
 * - 4.18.2019 rcnet
 * - 6.1.2019 rcnet - Added claim authorization hooks
 * - 11.29.2019 rcnet - Added Feature Flag checking
 */
import React from "react";
import { Redirect, Route } from "react-router-dom";
import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";
import Auth from "./../../../core/auth/auth";

/**
 * This component will check for any claim based rules attached in the route.
 * @param {*} props
 */
const CheckAndRenderAuthenticatedComponent = props => {
  let noAccess = false;
  if (
    !isNil(props.claimsAuthorisationHook) &&
    isFunction(props.claimsAuthorisationHook)
  ) {
    noAccess = !props.claimsAuthorisationHook();
  }

  if (noAccess) {
    // send to Forbidden page
    return (
      <Redirect
        to={{
          pathname: "/pages/error-403"
        }}
      />
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

const PrivateRoute = ({
  component,
  path,
  claimsAuthorisationHook,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      Auth.isAuthenticated() ? (
        <CheckAndRenderAuthenticatedComponent
          claimsAuthorisationHook={claimsAuthorisationHook}
          {...props}
        >
          {React.createElement(component, props)}
        </CheckAndRenderAuthenticatedComponent>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
