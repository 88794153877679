import { combineReducers } from "redux";
import SystemReducer from "./system/system-reducer";
import ContactUsReducer from "./../views/contact-us/redux/reducer";

const RootReducer = combineReducers({
  system: SystemReducer,
  contactUs: ContactUsReducer,
});

export default RootReducer;
