import { ActionTypes } from "./system-action-types";
import isNil from "lodash/isNil";

const initialState = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  mobile: "",
  memberType: "",
  isFetching: false,
  isLoggingOut: false,
};

export default function UserProfileReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SYS_GET_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypes.SYS_GET_PROFILE_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        email: action.payload.email,
        mobile: action.payload.mobile,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        memberType: action.payload.memberType,
        isFetching: false,
      };

    case ActionTypes.SYS_GET_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    case ActionTypes.SYS_HIDE_SPLASH:
      return {
        ...state,
      };

    case ActionTypes.SYS_TOGGLE_LOG_OUT:
      return {
        ...state,
        isLoggingOut: !state.isLoggingOut,
      };

    case ActionTypes.SYS_UPDATE_PROFILE:
      return {
        ...state,
        email: action.payload.email,
        mobile: action.payload.mobile,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };

    default:
      return state;
  }
}

const sanitiseValue = (value) => {
  if (typeof value === "boolean") {
    return isNil(value) ? false : value;
  }

  return isNil(value) ? "" : value;
};

/**
 * Selectors
 */

export const selectProfileData = (state) => state.system.userProfile;
