/**
 * Configure additional middleware here
 */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./root-reducer";

const ConfigureStore = () => {
  let middleware = [thunk];
  let enhancers = [applyMiddleware(...middleware)];

  if (
    process.env.NODE_ENV === "development" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    enhancers.push(
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  }

  return createStore(RootReducer, compose(...enhancers));

  // // Grab the state from a global variable injected into the server-generated HTML
  // const preloadedState = window.__PRELOADED_STATE__;

  // // Allow the passed state to be garbage-collected
  // delete window.__PRELOADED_STATE__;

  // // Create Redux store with initial state
  // const store = createStore(
  //   RootReducer,
  //   preloadedState || ,
  //   compose(...enhancers)
  // );

  // // Tell react-snap how to save Redux state
  // window.snapSaveState = () => ({
  //   __PRELOADED_STATE__: store.getState()
  // });

  // return store;
};

export default ConfigureStore;
