/**
 * HttpInterceptor abstraction
 * To encapsulate http provider details like FETCH Api or Axios.
 *
 * Provider: Axios
 * - rcnet @solvable
 */

import axios from "axios";
import Auth from "../auth/auth";
import historyObject from "../history/historyObject";
import Config from "./../../config";

const AUTH_LOGIN_URL = "/login";
const AUTH_TOKEN_URL = "/v1/core/auth/token";
const MAINTENANCE_PAGE_URL = "/pages/maintenance";
const FORBIDDEN_PAGE_URL = "/pages/error-403";

const HttpInterceptor = () => {
  const api = axios.create({ baseURL: Config.apiUrl });
  let authTokenRequestPromise;

  // By default interceptors were enabled, this will impact setAuthToken rendering it no effect at all.
  // Todo: Provide a way to enable /disable interceptor specially the auth alteration when setAuthToken method is called.
  const interceptorReqAuth = (config) => {
    const token = Auth.getToken();
    if (token != null) config.headers.Authorization = `Bearer ${token}`;

    return config;
  };

  // AuthTokenRequestPromise plays a very important role in this code base, it handles concurrent/parallel requests which puts into queue.
  // To prevent multiple refreshtoken
  const interceptorRespError = (error) => {
    const err = error.response;

    // Check if api server is down
    if (error.Error === "Network Error" && err === undefined) {
      historyObject.push(MAINTENANCE_PAGE_URL);
      return Promise.reject(error);
    }

    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
      return _getAuthToken()
        .then((resp) => {
          Auth.setToken(
            resp.data.access_token,
            resp.data.refresh_token,
            resp.data.expires_in
          );
          err.config.__isRetryRequest = true;
          err.config.headers.Authorization = `Bearer ${resp.data.access_token}`;

          return axios(err.config);
        })
        .catch((e) => {
          let ex = e.response;
          if (ex.status === 400 && ex.data && ex.data.invalid_refreshtoken) {
            Auth.removeToken();
            historyObject.push(AUTH_LOGIN_URL);
          }
          return Promise.reject(e);
        });
    } else if (err.status === 403) {
      historyObject.push(FORBIDDEN_PAGE_URL);
      let newErrObj = Object.assign({}, error);
      newErrObj.response.data["Message"] = "Unable to access page.";
      return Promise.reject(newErrObj);
    } else {
      return Promise.reject(error);
    }
  };

  const _getAuthToken = () => {
    if (!authTokenRequestPromise) {
      const refreshTokenPayload = Auth.getRefreshTokenPayload(Config.clientId);

      authTokenRequestPromise = api.post(AUTH_TOKEN_URL, refreshTokenPayload);
      authTokenRequestPromise
        .then(_resetAuthTokenRequestPromise)
        .catch(_resetAuthTokenRequestPromise);
    }

    return authTokenRequestPromise;
  };

  const _resetAuthTokenRequestPromise = () => (authTokenRequestPromise = null);

  return {
    interceptorReqAuth,
    interceptorRespError,
  };
};

export default HttpInterceptor;
