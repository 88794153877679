/**
 * Error Service
 */
const ErrorService = () => {
  const resolveError = error => {
    const deferred = new Promise((resolve, reject) => {
      let errObj = _identifyAndCreateErrorObject(error);
      resolve(errObj);
    });

    return deferred;
  };

  const _identifyAndCreateErrorObject = error => {
    const status = error.response && error.response.status;
    const errData = error.response && error.response.data;
    let defaultErrModel = {
      isModelState: false,
      error: errData,
      status: status
    };

    _log(error);

    if (status === undefined) {
      return {
        ...defaultErrModel,
        status: "Unknown",
        message:
          "Oops, something went wrong. Check the console logs for details."
      };
    }

    switch (status) {
      case 404:
        return {
          ...defaultErrModel,
          message: errData
        };

      case 500:
        return {
          ...defaultErrModel,
          message: errData.Message
        };

      default:
        return {
          ...defaultErrModel,
          message: "Oops, something went wrong."
        };
    }
  };

  // Perform Sentry logging here
  const _log = error => {
    console.log("== Encountered an error ==");
    if (error && error.response && error.response.data)
      console.error(error.response.data);
    else console.error(error);
  };

  return {
    resolveError
  };
};

export default ErrorService;
