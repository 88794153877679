import loadable from "@loadable/component";

const FullLayout = loadable(() => import("./../layouts/fullLayout"));
const BlankLayout = loadable(() => import("./../layouts/blankLayout"));

var indexRoutes = [
  { path: "/pages", name: "System Misc. Pages", component: BlankLayout },
  { path: "/", name: "Home", component: FullLayout },
];

export default indexRoutes;
