/**
 * Toast Notification
 * Refer to: https://www.npmjs.com/package/react-notification-system#action
 */
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";

class ToastNotification extends Component {
  // Override default style
  style = {
    NotificationItem: {
      // Override the notification item
      DefaultStyle: {
        // Applied to every notification, regardless of the notification level
        margin: "10px 5px 2px 1px",
        padding: "15px",
        borderRadius: "4px",
        fontSize: "1em",
        color: "white",
        backgroundColor: "#313131",
        width: "500px",
        maxWidth: "100%",
      },
      success: {
        // Applied only to the success notification item
        borderTop: "4px solid #04D9B2",
        //fontWeight: 'bolder',
      },
      error: {
        // Applied only to the error notification item
        borderTop: "4px solid red",
        //fontWeight: 'bolder',
      },
    },
    Dismiss: {
      success: {
        color: "white",
        backgroundColor: "#313131",
      },
    },
  };

  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem;
  }

  componentWillReceiveProps(newProps) {
    const { message, level } = newProps.notification;
    this.notificationSystem.addNotification({
      message,
      level,
      position: "tr",
    });
  }

  render() {
    return <NotificationSystem ref="notificationSystem" style={this.style} />;
  }
}

export default ToastNotification;
