import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/common/scroll-to-top";
import ToastNotificationContainer from "./components/common/toast/toast-container";
import indexRoutes from "./routes";
import PrivateRoute from "./components/common/private-route";
import "./App.scss";
import "./custom.scss";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <ToastNotificationContainer />
        <div className="sw-app custom-styles">
          <Switch>
            {indexRoutes.map((prop, key) => {
              if (prop.privateRoute) {
                return (
                  <PrivateRoute
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              } else {
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              }
            })}
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  );
};

export default App;
