/**
 * Common service for Profile
 */
import HttpClient from "../core/http/httpClient";

const ProfileService = () => {
  let httpClient = HttpClient();

  const getProfile = id =>
    httpClient.get(`/v1/core/accounts/${id}/profile/settings`);

  return {
    getProfile
  };
};

export default ProfileService;
