import { ActionTypes } from "./action-types";

const initialState = {
  data: [],
  isLoadingNews: false,
};

export default function PublicNewsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PUBLIC_NEWS_LOAD_REQUEST:
      return {
        ...state,
        isLoadingNews: true,
      };
    case ActionTypes.PUBLIC_NEWS_LOAD_SUCCESS:
      return {
        ...state,
        isLoadingNews: false,
        data: action.payload,
      };
    case ActionTypes.PUBLIC_NEWS_LOAD_FAILURE:
      return {
        ...state,
        isLoadingNews: false,
      };
    default:
      return state;
  }
}
