/**
 * System-wide Action Creator definitions
 */
import { ActionTypes } from "./system-action-types";
import UserIdentity from "./../../core/auth/userIdentity";
import ErrorService from "./../../core/error/errorService";
import ProfileService from "./../../services/profile-service";

// =================
//  Notifications
// =================
export function addNotification(message, level) {
  return {
    type: ActionTypes.SYS_ADD_NOTIFICATION,
    message,
    level,
  };
}

/*
  error - Error object from the api response
  defaultErrorMessage - fallback error message (defined by the user)
*/
export function addErrorNotification(error, defaultErrorMessage) {
  let errorMessage =
    error &&
    error.response &&
    error.response.status &&
    error.response.status === 500
      ? error.response.data.Message
      : defaultErrorMessage;

  return {
    type: ActionTypes.SYS_ADD_ERROR_NOTIFICATION,
    message: errorMessage,
    level: "error",
  };
}

// ==================
//  Handle Error
// ==================
export const handleError = (error) => {
  return (dispatch) => {
    const errorService = ErrorService();
    //dispatch(resetPasswordRequest());
    errorService
      .resolveError(error)
      .then((resp) => {
        dispatch(showErrorForm(resp));
      })
      .catch((err) => {
        dispatch(hideErrorForm());
      });
  };
};

const showErrorForm = (data) => ({
  type: ActionTypes.SYS_HANDLE_ERROR_SHOW_FORM,
  payload: {
    message: data.message,
    error: data.error,
    isModelState: data.isModelState,
    status: data.status,
  },
});

export const hideErrorForm = () => ({
  type: ActionTypes.SYS_HANDLE_ERROR_HIDE_FORM,
});

// ==================
//  Profile
// ==================
export const getProfile = () => (dispatch) => {
  const service = ProfileService();
  const userIdent = UserIdentity();

  if (userIdent.isAuthenticated()) {
    dispatch(getProfileRequest());
    service
      .getProfile(userIdent.getUserId())
      .then(({ data }) => {
        dispatch(getProfileSuccess(data));
      })
      .catch((err) => {
        dispatch(getProfileFailure(err));
      });
  }
};

export const setProfile = (data) => {
  return (dispatch) => {
    dispatch(getProfileSuccess(data));
  };
};

const getProfileRequest = () => ({
  type: ActionTypes.SYS_GET_PROFILE_REQUEST,
});

const getProfileSuccess = (data) => ({
  type: ActionTypes.SYS_GET_PROFILE_SUCCESS,
  payload: data,
});

export const updateUserProfile = (payload) => ({
  type: ActionTypes.SYS_UPDATE_PROFILE,
  payload,
});

const getProfileFailure = (error) => ({
  type: ActionTypes.SYS_GET_PROFILE_FAILURE,
  payload: {
    error,
  },
});

export const hideSplash = () => ({
  type: ActionTypes.SYS_HIDE_SPLASH,
});

export const toggleLogOut = () => ({
  type: ActionTypes.SYS_TOGGLE_LOG_OUT,
});
