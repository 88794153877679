/**
 * System Reducers
 */
import { combineReducers } from "redux";
import NotificationReducer from "./notification-reducer";
import ErrorNotificationReducer from "./error-notification-reducer";
import UserProfileReducer from "./user-profile-reducer";
import PublicNewsReducer from "./public-news/reducer";

/**
 * System functionalities
 */
const SystemReducer = combineReducers({
  notification: NotificationReducer,
  errorNotification: ErrorNotificationReducer,
  userProfile: UserProfileReducer,
  publicNews: PublicNewsReducer,
});

export default SystemReducer;
