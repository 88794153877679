import { ActionTypes } from './system-action-types';

const initialState = {
    title: '',
    message: '',
    lastErrorOccured: null,
    isModalOpen: false,
    isModelStateError: false,
    status: null
};

export default function ErrorNotificationReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SYS_HANDLE_ERROR_SHOW_FORM:
            return {
                ...state,
                title: 'Application Error',
                message: action.payload.message,
                lastErrorOccured: action.payload.error,
                isModelStateError: action.payload.isModelState,
                isModalOpen: true,
                status: action.payload.status,
            };

        case ActionTypes.SYS_HANDLE_ERROR_HIDE_FORM: 
            return {
                ...state,
                isModalOpen: false,
            };

        default:
            return state;
    }
}

/**
 * Selectors
 */

export const selectErrorNotification = state => state.system.errorNotification;