const ActionTypes = {
  CONTACT_US_REQUEST: "CONTACT_US_REQUEST",
  CONTACT_US_SUCCESS: "CONTACT_US_SUCCESS",
  CONTACT_US_FAILURE: "CONTACT_US_FAILURE",

  CONTACT_US_QUOTE_REQUEST: "CONTACT_US_QUOTE_REQUEST",
  CONTACT_US_QUOTE_SUCCESS: "CONTACT_US_QUOTE_SUCCESS",
  CONTACT_US_QUOTE_FAILURE: "CONTACT_US_QUOTE_FAILURE",

  CONTACT_US_CLEAN_UP: "CONTACT_US_CLEAN_UP",
};

export default ActionTypes;
